function Navbar()
{
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-dark box-shadow mb-0 position-sticky">
            <div className="container-fluid">
                <div className="col-lg-2 col-md-6 col-11">
                    <a className="navbar-brand font-weight-bolder text-sm-caps w-10" href="/">
                        <img
                            className="img-fluid mx-2"
                            id="jp-img"
                            src="Images/JP.png"
                            alt="Me"
                            width="32"
                        />
                        <span className="">Development</span>
                    </a>
                </div>
                <div className="col-lg-2 col-1 d-inline-flex justify-content-end">
                    <button className="navbar-toggler bg-lighter-dark" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse" aria-controls="navbarSupportedContent"
                        aria-expanded="true" aria-label="Toggle navigation">
                        {/* <i class="bi bi-chevron-bar-down"></i> */ }
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="navbar-collapse collapse d-lg-inline-flex col-lg-8 col-md-8 col-8">
                    <ul className="navbar-nav col-12">
                        <div className="col-lg-6 d-lg-inline-flex justify-content-lg-center gap-lg-5">
                            <li className="nav-item m-0">
                                <a className="nav-link text-lg-start font-size-lg-100 font-size-150 text-center" href="/about">About</a>
                            </li>
                            <li className="nav-item m-0">
                                <a className="nav-link text-lg-start font-size-lg-100 font-size-150 text-center" href="/services">Services</a>
                            </li>
                            <li className="nav-item m-0">
                                <a className="nav-link text-lg-start font-size-lg-100 font-size-150 text-center" href="/pricing">Pricing</a>
                            </li>
                            <li className="nav-item m-0">
                                <a className="nav-link text-lg-start font-size-lg-100 font-size-150 text-center" href="/contact">Contact</a>
                            </li>
                        </div>
                        <div className="col-lg-6 col-12 d-lg-inline-flex justify-content-end">
                            <li className="nav-item m-0">
                                <a className="nav-link text-lg-end font-size-lg-100 font-size-150 text-white text-center" href="/login">Login</a>
                            </li>
                        </div>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
export default Navbar;



