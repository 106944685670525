import './About.css';

function About()
{
    return (
        <div>
            <br />
            <h3 className="text-center">
                We are a team of professional Software Engineers located in the Fox Cities of Wisconsin looking to help your small business grow.
            </h3>
            <br />
            <h4 className="text-center">
                We primarily service customers within the combined statistical area of Appleton, Neenah, Oshkosh, Green Bay, and Fond Du Lac. Customers seeking our services outside of this area will require a remote-work agreement prior to the beginning of any work.
            </h4>
        </div>
    );
}

export default About;
