function Footer()
{
    return (
        <footer className="footer text-muted fixed-bottom bg-light opacity-50">
            <div className="container text-center text-secondary">
                <p className="mt-1 mb-0">Copyright &copy; 2024 J.P. Software Development Services LLC. All rights reserved.</p>
            </div>
        </footer>
    );
}

export default Footer;