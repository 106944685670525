function Contact() 
{
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="border shadow rounded-15 mt-4 col-8">
                <div className="bg-secondary rounded-top-15">
                    <h2 className="p-3 text-white text-center">Contact</h2>
                </div>
                {/* FIXME: change method from POST */ }
                <form className="form p-4" method="GET" action="mailto:support@jpdev.net">
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="name">Name:</label>
                        <input className="form-control" id="name" name="name" type="text" placeholder="name" minLength="2" maxLength="80" required></input>
                    </div>
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="email">Email:</label>
                        <input className="form-control" id="email" name="email" type="email" placeholder="email" minLength="6" maxLength="80" required></input>
                    </div>
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="phone">Phone:</label>
                        <input className="form-control" id="phone" name="phone" type="tel" placeholder="(xxx) xxx-xxxx" pattern="[(]?[0-9]{3}[)]?[\s-]*[0-9]{3}[\s-]*[0-9]{4}" required></input>
                    </div>
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="company">Company:</label>
                        <input className="form-control" id="company" name="company" type="text" placeholder="company" minLength="2" maxLength="80"></input>
                    </div>
                    <div className="form-group py-1 pb-2 col-12">
                        <label className="form-label p-0" htmlFor="subject">Comments</label>
                        <textarea className="form-control" id="subject" name="subject" type="text" placeholder="questions or comments here" minLength="10" maxLength="300" required></textarea>
                    </div>
                    <div className="row gap-1 mt-4 mx-2 justify-content-center">
                        <button className="btn btn-outline-danger col" type="reset">
                            Reset <i className="bi bi-arrow-clockwise"></i>
                        </button>
                        <button className="btn btn-primary col" type="submit">
                            Submit <i className="bi bi-plus-circle"></i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Contact;