function Pricing() 
{
    return (
        <div>
            <br />
            <p>Contact us today to receive an estimate. Our teams will reach back out to you to discuss the details and create a project timeline.</p>
        </div>
    );
}

export default Pricing;;