import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/common.css';
import Layout from './components/layout/Layout';
import reportWebVitals from './scripts/reportWebVitals';

const root = ReactDOM.createRoot( document.getElementById( 'root' ) );

root.render(
    <React.StrictMode>
        <Layout>
        </Layout>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
