import React from 'react';
import
{
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';
import About from '../../components/about/About';
import Services from '../../components/services/Services';
import Pricing from '../../components/pricing/Pricing';
import Contact from '../../components/contact/Contact';
import Login from '../../components/login/Login';



function Layout( { children } )
{
    return (
        <div>
            <Navbar />
            <main
                className="container">
                <br />
                <Router>
                    { children }
                    <Routes>
                        <Route exact path="/" element={ <About /> } />
                        <Route path="/about" element={ <About /> } />
                        <Route path="/services" element={ <Services /> } />
                        <Route path="/pricing" element={ <Pricing /> } />
                        <Route path="/contact" element={ <Contact /> } />
                        <Route path="/login" element={ <Login /> } />
                    </Routes>
                </Router>
                <br />
            </main>
            <Footer />
        </div>
    );
};

export default Layout;