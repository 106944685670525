function Services() 
{
    return (
        <div>
            <br />
            <h3>Small business looking to modernize proceses and improve the optics of your brand?</h3>
            <br />
            <div className="m-5 mt-0">
                <p>We offer a variety of software services to help small businesses optimize their processes including building tailor-made software for you and your business.</p>
                <p>Have a new project idea you want to bring to life? Here is what we offer:</p>
                <ul>
                    <li>Web Integration</li>
                    <li>Websites & Web Applications</li>
                    <li>Digital Databases & Storage</li>
                    <li>Mobile Applications</li>
                    <li>Desktop Applications</li>
                    <li>Consultancy</li>
                </ul>
                <p>Or do you have an existing project idea that you need worked on, we are here to help. Please email <a href="mailto:support@jpdev.net">support@jpdev.net</a></p>
            </div>
        </div>
    );
}

export default Services;